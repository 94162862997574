import { ApolloProvider } from '@apollo/react-hooks'
import { BaseProvider, DefaultTheme, Pl2kTheme } from '@parkholidays/legacy_components';
import Amplify from 'aws-amplify'
import React from 'react'
import ReactDOM from 'react-dom'
import { HelmetProvider } from 'react-helmet-async'
import { BrowserRouter } from 'react-router-dom'
import { Client as Styletron } from 'styletron-engine-atomic'
import { DebugEngine, Provider as StyletronProvider } from 'styletron-react'
import App from './App'
import Head from './components/Head/Head'
import { AccountsProvider } from './context/accounts'
import { AuthProvider } from './context/auth'
import { ThemeProvider } from './context/theme'
import './index.css'
import {isParkLeisureContext} from "./helpers/hostNameCheck";
import { apolloClient } from './services/api/Apollo'
import { MY_PARK_HOLIDAYS, MY_PARK_LEISURE } from './constants'
import {datadogLogger, initDatadogConsoleLogger} from "./utils/datadogLogger/logger";

initDatadogConsoleLogger()

const defaultDomain = isParkLeisureContext() ?  '.parkleisure.co.uk'  : '.parkholidays.com'
const domain = process.env.REACT_APP_COOKIE_DOMAIN || defaultDomain
const isPl2k = isParkLeisureContext()
const isRunningLocally = domain === 'localhost'
const debug = isRunningLocally ? new DebugEngine() : undefined

// 1. Create a client engine instance
const engine = new Styletron()

datadogLogger.log(`IsParkleisureContext ${isParkLeisureContext()}`)
datadogLogger.log(`isRunningLocally ${isRunningLocally}`);
datadogLogger.log(`window.location.hostname ${window.location.hostname}`);

Amplify.configure({
  Auth: {
    // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
    // identityPoolId: 'XX-XXXX-X:XXXXXXXX-XXXX-1234-abcd-1234567890ab',
    // REQUIRED - Amazon Cognito Region
    region: 'eu-west-2',
    // OPTIONAL - Amazon Cognito Federated Identity Pool Region
    // Required only if it's different from Amazon Cognito Region
    // identityPoolRegion: 'XX-XXXX-X',
    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: isParkLeisureContext() ? 'eu-west-2_c9JDMAsNq' : 'eu-west-2_h7xryiZxh',
    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: isParkLeisureContext() ? '4fbb0c7mps1ft52v4r2k9elmic' : '2arps1b262ekog52bjuvc8n4eg',
    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: false,
    // OPTIONAL - Configuration for cookie storage
    // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
    cookieStorage: {
      // REQUIRED - Cookie domain (only required if cookieStorage is provided)
      domain,
      // OPTIONAL - Cookie path
      path: '/',
      // OPTIONAL - Cookie expiration in days
      expires: 365,
      // OPTIONAL - Cookie secure flag
      // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
      secure: !isRunningLocally,
    },
  },
})

const isProduction = !window.location.href.includes('staging');
console.log('isProduction', isProduction);

ReactDOM.render(
  <HelmetProvider>
    <Head brand={isPl2k ? MY_PARK_LEISURE : MY_PARK_HOLIDAYS} />
    <StyletronProvider value={engine} debug={debug} debugAfterHydration>
      <ThemeProvider>
        <BaseProvider theme={isPl2k ? Pl2kTheme : DefaultTheme}>
          <ApolloProvider client={apolloClient}>
            <AuthProvider>
              <AccountsProvider>
                <BrowserRouter>
                  <App isProduction={isProduction}/>
                </BrowserRouter>
              </AccountsProvider>
            </AuthProvider>
          </ApolloProvider>
        </BaseProvider>
      </ThemeProvider>
    </StyletronProvider>
  </HelmetProvider>,
  document.getElementById('root'),
)
